import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import CollectAndDeliverApi, {
  CollectAndDeliver,
  CollectAndDeliverDeleteForm,
  CollectAndDeliverRegisterForm,
  CollectAndDeliverUpdateForm,
} from '../../services/http/collectAndDeliver.api';
import { useAuthContext } from '../../store/auth.store';

/**
 * 収集して配信検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useCollectAndDeliverSearchApi() {
  const [data, setData] = useState<CollectAndDeliver[] | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (params?: Record<string, any>): Promise<CollectAndDeliver[]> => {
    try {
      const doc = await CollectAndDeliverApi.search(params);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }, []);
  return {
    request, data, error,
  };
}

/**
 * 収集して配信登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useCollectAndDeliverRegisterApi() {
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: CollectAndDeliverRegisterForm): Promise<void> {
    try {
      return await CollectAndDeliverApi.register(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }
  return {
    request, error,
  };
}

/**
 * 収集して配信更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useCollectAndDeliverUpdateApi() {
  const [error, setError] = useState<ApiError | null>(null);
  const { user } = useAuthContext();

  async function request(form: Omit<CollectAndDeliverUpdateForm, 'updateUser'>): Promise<void> {
    try {
      return await CollectAndDeliverApi.update({ ...form, updateUser: user?.userName || '' });
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }
  return {
    request, error,
  };
}

/**
 * 収集して配信削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useCollectAndDeliverDeleteApi() {
  const [error, setError] = useState<ApiError | null>(null);
  const { user } = useAuthContext();

  async function request(form: Omit<CollectAndDeliverDeleteForm, 'updatedBy'>): Promise<void> {
    try {
      return await CollectAndDeliverApi.delete({ ...form, updatedBy: user?.userName || '' });
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }
  return {
    request, error,
  };
}
