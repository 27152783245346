import { DateTime } from 'luxon';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import mainStyles from '../main.module.css';
import styles from './dispatchModal.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import DatePickerJp from '../../components/DatePickerJp';
import BaseText from '../../components/BaseText/BaseText';
import Formatter from '../../utils/formatters';
import {
  DeliveryAddressSpecify,
  DeliveryConfirmationRequest, DeliveryServiceList,
  DeliveryType,
  DocumentId,
  DocumentWarehousingStoreForm, GetDeliveryCalendarRequestForm,
} from '../../services/http/documentWarehousing.api';
import Validator from '../../utils/validators';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import {
  useDeliveryCalendarApi,
  useDeliveryMethodApi,
  useDocumentWarehousingStoreApi,
} from '../../hooks/api/documentWarehousing.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import { CollectAndDeliver } from '../../services/http/collectAndDeliver.api';
import { useCollectAndDeliverSearchApi } from '../../hooks/api/collectAndDeliver.hook';
import { RequestType } from '../../services/http/reservationCart.api';
import LoadingOverlay from '../../components/LoadingOverlay';
import SelectInput from '../../components/SelectInput';
import { useShipReserveApi, useShipReserveGetCalendarApi } from '../../hooks/api/reserve.hook';
import { DeliveryTime } from '../../utils/warehouse.utils';
import AutoCompleteMUI from '../../components/AutoCompleteMUI/AutoCompleteMUI';

export interface Props {
  onCancel: () => void,
  onSuccess: () => void,
  onError: () => void,
  documentIdList: DocumentId[],
  onDocumentRequestDone: () => void,
}

function Component({
  onCancel, onSuccess, onError, documentIdList, onDocumentRequestDone,
}: Props) {
  // hooks
  const openMessageModal = useMessageModal();

  // Apis
  const storeDocumentWarehousing = useDocumentWarehousingStoreApi();
  const { request: createLog } = useCreateLogApi();
  const { request: collectAndDeliverSearchApiRequest } = useCollectAndDeliverSearchApi();
  const { request: getDeliveryMethod, loading: deliveryMethodLoading } = useDeliveryMethodApi();
  const { request: getDeliveryCalendar, loading: deliveryCalendarLoading } = useDeliveryCalendarApi();
  const getShipReserveCalendar = useShipReserveGetCalendarApi();
  const shipReserve = useShipReserveApi();

  // states
  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [form, setForm] = useState<DocumentWarehousingStoreForm>({});
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [selectedCollectAndDeliver, setSelectedCollectAndDeliver] = useState<CollectAndDeliver | null>(null);
  const [collectAndDelivers, setCollectAndDelivers] = useState<CollectAndDeliver[]>([]);
  const [shortestDesiredDate, setShortestDesiredDate] = useState<string | null>(null);
  const [lastDesiredDate, setLastDesiredDate] = useState<string | null>(null);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<DeliveryServiceList | null>(null);
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryServiceList[]>([]);

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<Date | ''>('');
  const [holidayList, setHolidayList] = useState<string[]>([]);

  // methods
  const createRequestForm = useCallback(
    (): DocumentWarehousingStoreForm => ({
      ...form,
      deliveryType: selectedDeliveryOption?.deliveryType,
      deliveryTimeCd: selectedDeliveryOption?.deliveryType === DeliveryType.TeradaDelivery ? DeliveryTime.NO_SPECIFIC_TIME : undefined,
      documentList: documentIdList,
    }),
    [form, selectedDeliveryOption, documentIdList],
  );

  const validateForm = useCallback((requestForm: DocumentWarehousingStoreForm): boolean => {
    if (!requestForm.deliveryCd) {
      openMessageModal('配送先を入力してください。');
      return false;
    }

    if (requestForm.trdContact && requestForm.trdContact && requestForm.trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return false;
    }

    if (requestForm.deliveryType === null || requestForm.deliveryType === undefined) {
      openMessageModal('全ての必須項目を入力してください');
      return false;
    }

    if (requestForm.customerStaffName == null || requestForm.customerStaffName === '') {
      openMessageModal('全ての必須項目を入力してください');
      return false;
    }

    if (selectedDeliveryOption?.deliveryType === DeliveryType.Courier && (!requestForm.deliveryCd || !requestForm.deliveryName || !requestForm.deliveryPhone)) {
      openMessageModal('全ての必須項目を入力してください');
      return false;
    }

    if (selectedDeliveryOption?.deliveryType === DeliveryType.TeradaDelivery
        && (!requestForm.deliveryCd || !requestForm.deliveryName
            || !requestForm.deliveryPhone || !requestForm.scheduledDate || !requestForm.deliveryTimeCd)) {
      openMessageModal('全ての必須項目を入力してください');
      return false;
    }

    return true;
  }, [selectedDeliveryOption, openMessageModal]);

  const handleStoreDocumentRequest = useCallback(async (requestForm: DocumentWarehousingStoreForm) => {
    try {
      await shipReserve.request(requestForm);
      return true;
    } catch (error) {
      setCloseAlertModal({
        ...closeAlertModal,
        text: (error as ApiError).message,
        open: true,
        onCancel() {
          setCloseAlertModal({ ...closeAlertModal, open: false });
          onError();
        },
      });
      return false;
    }
  }, [shipReserve, closeAlertModal, onError]);

  const showSuccessModal = useCallback(() => {
    setCloseAlertModal({
      ...closeAlertModal,
      text: '依頼が完了しました。',
      open: true,
      onCancel() {
        setCloseAlertModal({ ...closeAlertModal, open: false });
        onSuccess();
      },
    });
  }, [closeAlertModal, onSuccess]);

  const handleSendForm = useCallback(async () => {
    setAlertModal({ ...alertModal, open: false });
    const requestForm = createRequestForm();

    const isRequestSuccessful = await handleStoreDocumentRequest(requestForm);
    if (!isRequestSuccessful) return;
    onDocumentRequestDone();

    showSuccessModal();
  }, [createRequestForm, validateForm, handleStoreDocumentRequest, showSuccessModal]);

  const handleSubmit = () => {
    const requestForm = createRequestForm();

    if (!validateForm(requestForm)) return;

    setAlertModal({
      open: true,
      text: '依頼を送信します。よろしいですか？',
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: handleSendForm,
    });
  };

  const shouldDisableDate = useCallback((dateParam: DateTime) => {
    if (!shortestDesiredDate || !lastDesiredDate) {
      return true;
    }

    const date = Formatter.reformatDateTime(dateParam, Formatter.defaultDateTimeFormat);

    const isBeforeShortestDate = date < Formatter.fromFormatToDateTime(shortestDesiredDate, Formatter.defaultDateTimeFormat);
    const isAfterLastDate = date > Formatter.fromFormatToDateTime(lastDesiredDate, Formatter.defaultDateTimeFormat);
    const isHoliday = holidayList.some((holiday) => date.hasSame(Formatter.fromFormatToDateTime(holiday, Formatter.defaultDateTimeFormat), 'day'));
    return isBeforeShortestDate || isAfterLastDate || isHoliday;
  }, [shortestDesiredDate, lastDesiredDate, holidayList]);

  const updateFormWithCollectAndDeliver = useCallback((collectAndDeliver: CollectAndDeliver) => {
    setForm({
      ...form,
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryName: collectAndDeliver.deliveryName,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
      customerStaffName: collectAndDeliver.deliveryName3,
      deliveryPhone: collectAndDeliver.deliveryPhone,
      deliveryFax: collectAndDeliver.deliveryFax,
    });
  }, [form]);

  const createDeliveryMethodForm = useCallback((collectAndDeliver: CollectAndDeliver): DeliveryConfirmationRequest => ({
    reservationType: RequestType.DISPATCH,
    deliveryAddressSpecify: DeliveryAddressSpecify.Specified,
    delivery: [{
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
    }],
  }), []);

  const createDeliveryCalendarRequestForm = useCallback((collectAndDeliver: CollectAndDeliver, selectedDelivery: DeliveryServiceList): GetDeliveryCalendarRequestForm | null => ({
    reservationType: RequestType.DISPATCH,
    deliveryServiceId: selectedDelivery.deliveryServiceId,
    delivery: [{
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
    }],
  }), []);

  const filterAndMapDeliveryOptions = useMemo(
    () => (deliveryServiceList: DeliveryServiceList[]) => deliveryServiceList
      .filter(
        (service) => service.deliveryType === DeliveryType.TeradaDelivery
                          || service.deliveryType === DeliveryType.Courier,
      )
      .map((service) => ({
        deliveryServiceId: service.deliveryServiceId,
        deliveryServiceName: service.deliveryServiceName,
        deliveryType: service.deliveryType,
      })),
    [],
  );

  const fetchDeliveryServiceList = useCallback(async (deliveryMethodForm: DeliveryConfirmationRequest) => {
    try {
      const res = await getDeliveryMethod(deliveryMethodForm);
      return res.deliveryServiceList;
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
      return [];
    }
  }, [getDeliveryMethod, openMessageModal]);

  const setDeliveryOptionsState = useCallback((deliveryServiceList: DeliveryServiceList[]) => {
    const options = filterAndMapDeliveryOptions(deliveryServiceList);
    setDeliveryOptions(options);
  }, [filterAndMapDeliveryOptions]);

  const fetchAndSetDeliveryCalendar = useCallback(async (deliveryMethodForm: GetDeliveryCalendarRequestForm) => {
    try {
      const response = await getDeliveryCalendar(deliveryMethodForm);
      setShortestDesiredDate(response.shortestDesiredDate);
      setLastDesiredDate(response.lastDesiredDate);
      setHolidayList(response.holidayList.map((holiday) => holiday.holiday));
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
    }
  }, [getDeliveryCalendar, openMessageModal]);

  const extractShipReserveCalendarForm = useCallback((collectAndDeliver: CollectAndDeliver) => ({
    deliveryCd: collectAndDeliver.deliveryCd,
    deliveryPostNo: collectAndDeliver.deliveryPostNo,
    deliveryAddress1: collectAndDeliver.deliveryAddress1,
    deliveryAddress2: collectAndDeliver.deliveryAddress2,
    deliveryAddress3: collectAndDeliver.deliveryAddress3,
  }), []);

  const handleShipReserveCalendarRequest = useCallback(async (collectAndDeliver: CollectAndDeliver) => {
    const getShipReserveCalendarForm = extractShipReserveCalendarForm(collectAndDeliver);

    try {
      const res = await getShipReserveCalendar.request(getShipReserveCalendarForm);
      setExpectedDeliveryDate(res.shortestDesiredDate);
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
    }
  }, [extractShipReserveCalendarForm, getShipReserveCalendar, openMessageModal]);

  const handleSelectedCollectAndDelivers = useCallback(async (collectAndDeliver: CollectAndDeliver) => {
    setSelectedDeliveryOption(null);

    await handleShipReserveCalendarRequest(collectAndDeliver);

    setSelectedCollectAndDeliver(collectAndDeliver);
    updateFormWithCollectAndDeliver(collectAndDeliver);

    const deliveryMethodForm = createDeliveryMethodForm(collectAndDeliver);

    const deliveryServiceList = await fetchDeliveryServiceList(deliveryMethodForm);
    const deliveryServiceListWithoutTerada = deliveryServiceList.filter(({ deliveryType }) => deliveryType !== DeliveryType.TeradaDelivery);
    const teradaDelivery = deliveryServiceList.find(({ deliveryType }) => deliveryType === DeliveryType.TeradaDelivery);

    if (teradaDelivery == null) {
      setDeliveryOptionsState(deliveryServiceListWithoutTerada);
      return;
    }
    const deliveryCalendarRequestForm = createDeliveryCalendarRequestForm(collectAndDeliver, teradaDelivery);

    if (deliveryCalendarRequestForm == null) return;

    const deliveryCalendar = await getDeliveryCalendar(deliveryCalendarRequestForm);
    const isteradaAvailable = deliveryCalendar.deliveryTimeList.some(({ deliveryTimeCd }) => deliveryTimeCd === DeliveryTime.NO_SPECIFIC_TIME);

    if (isteradaAvailable) setDeliveryOptionsState(deliveryServiceList);
    else setDeliveryOptionsState(deliveryServiceListWithoutTerada);
  }, [handleShipReserveCalendarRequest, updateFormWithCollectAndDeliver, createDeliveryMethodForm, fetchDeliveryServiceList, setDeliveryOptionsState, createDeliveryCalendarRequestForm, getDeliveryCalendar]);

  const clearCollectionAndDeliver = useCallback(() => {
    setExpectedDeliveryDate('');
    setSelectedDeliveryOption(null);
    setSelectedCollectAndDeliver(null);
    setFromDate(null);
    setForm({
      ...form,
      deliveryCd: undefined,
      deliveryName: undefined,
      deliveryPostNo: undefined,
      deliveryAddress1: undefined,
      deliveryAddress2: undefined,
      deliveryAddress3: undefined,
      customerStaffName: undefined,
      deliveryFax: undefined,
      scheduledDate: undefined,
      deliveryTimeCd: undefined,
      trdContact: '',
      userMemo: '',
      deliveryPhone: undefined,
    });
  }, [form]);

  const mapDeliveryOptions = useCallback(() => deliveryOptions.map((option) => ({
    value: option.deliveryServiceId,
    text: option.deliveryServiceName,
  })), [deliveryOptions]);

  const fetchDeliveryCalendar = useCallback(async (selectedDelivery: DeliveryServiceList) => {
    if (!selectedCollectAndDeliver) return;
    const deliveryCalendarRequestForm = createDeliveryCalendarRequestForm(selectedCollectAndDeliver, selectedDelivery);

    if (!deliveryCalendarRequestForm) return;
    await fetchAndSetDeliveryCalendar(deliveryCalendarRequestForm);
  }, [createDeliveryCalendarRequestForm, fetchAndSetDeliveryCalendar, selectedCollectAndDeliver]);

  const handleDeliveryOptionChange = useCallback(
    async (selectedValue: string) => {
      const selected = deliveryOptions.find((option) => option.deliveryServiceId === selectedValue);

      if (selected) {
        setSelectedDeliveryOption(selected);
        await fetchDeliveryCalendar(selected);
      }
    },
    [deliveryOptions, fetchDeliveryCalendar, setSelectedDeliveryOption],
  );

  // Memos
  const collectAndDeliverOptions = useMemo(
    () => {
      const res = collectAndDelivers.map((item) => ({ label: `${item.deliveryCd}：${item.deliveryName}`, value: item }));
      return [{ label: '', value: undefined as unknown as CollectAndDeliver }, ...res];
    },
    [collectAndDelivers],
  );

  // effects
  useEffectOnce(() => {
    createLog(LogFormName.IssueRequestScreen, LogControlName.Show);
  });

  useEffect(() => {
    collectAndDeliverSearchApiRequest({ actFlg: 1 }).then((res) => setCollectAndDelivers(res));
  }, []);

  return (
    <ScreenModal
      customStyles={{
        minWidth: '800px',
        maxHeight: '90%',
        border: 'var(--defaultBorder)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          出庫依頼
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>対象件数</div>
          <div className={styles['form-right']}>
            <BaseText size="smMd">
              {documentIdList.length}
              件
            </BaseText>
          </div>
        </div>

        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            集配先
            <span className={styles['required-asterisk']}>*</span>
          </div>
          <div className={styles['form-right']}>
            <AutoCompleteMUI
              value={selectedCollectAndDeliver ? `${selectedCollectAndDeliver.deliveryCd}：${selectedCollectAndDeliver.deliveryName}` : ''}
              options={collectAndDeliverOptions}
              onSelect={handleSelectedCollectAndDelivers}
              menuStyle={{ width: '100%' }}
              onDeselect={() => {
                clearCollectionAndDeliver();
              }}
            />
            <div className={styles['form-right-detail']} style={{ height: '42px' }}>
              {selectedCollectAndDeliver && (
              <>
                <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                  <span style={{ marginRight: '5px' }}>〒</span>
                  {' '}
                  {selectedCollectAndDeliver?.deliveryPostNo}
                </div>
                {selectedCollectAndDeliver?.deliveryAddress1}
                {', '}
                {selectedCollectAndDeliver?.deliveryAddress2}
                {', '}
                {selectedCollectAndDeliver?.deliveryAddress3}
              </>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles['form-row']} ${styles['no-padding-bottom']}`}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            集配方法
            <span className={styles['required-asterisk']}>*</span>
          </div>
          <div className={styles['form-right']}>
            <SelectInput
              value={selectedDeliveryOption?.deliveryServiceId || ''}
              options={mapDeliveryOptions()}
              onChange={handleDeliveryOptionChange}
              menuStyle={{ width: '100%' }}
              disabled={!selectedCollectAndDeliver}
            />
          </div>
        </div>
        <div className={styles['form-row']} style={{ marginTop: 15 }}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            担当者名
            <span className={styles['required-asterisk']}>*</span>
          </div>
          <div className={styles['form-right']}>
            <Input
              value={form.customerStaffName || ''}
              onChange={(e) => setForm({ ...form, customerStaffName: e })}
              disabled={!selectedCollectAndDeliver}
            />
          </div>
        </div>

        <div className={`${styles['form-row']}`}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            TEL
            <span className={styles['required-asterisk']}>*</span>
          </div>
          <div className={`${styles['form-right']} ${mainStyles['mr-20px']}`}>
            <Input
              value={form?.deliveryPhone || ''}
              onChange={(e) => setForm({ ...form, deliveryPhone: e })}
              disabled={!selectedCollectAndDeliver}
            />
          </div>
        </div>

        {(selectedDeliveryOption?.deliveryType === DeliveryType.Courier || selectedDeliveryOption === null) ? (

          <div className={styles['form-row']}>
            <div className={`${styles.label} ${styles['form-left']}`}>
              出庫予定日
            </div>
            <div className={styles['form-right']}>
              {expectedDeliveryDate && (
              <div style={{ display: 'flex' }}>
                <div>
                  {Formatter.toDisplayDate(expectedDeliveryDate, Formatter.defaultDateFormat)}
                </div>
                <div className={styles.info}>
                  <input
                    className={styles['info-button']}
                    type="image"
                    src="/images/Warning.svg"
                    alt="bubble-button"
                  />
                  <div className={styles['info-bubble']}>
                    <div>配送センターでの受け取り期限を過ぎると荷物が廃棄される可能性があります。</div>
                    <div>受取日のご希望がある場合は「寺田倉庫への連絡事項」の欄にご希望の日付をご記入ください。</div>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={styles['form-row']}>
              <div className={`${styles.label} ${styles['form-left']}`}>FAX</div>
              <div className={styles['form-right']}>
                <Input
                  value={form?.deliveryFax || ''}
                  onChange={(e) => setForm({ ...form, deliveryFax: e })}
                  disabled={!selectedCollectAndDeliver}
                />
              </div>
            </div>
            <div className={styles['form-row']}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                受取希望日
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={styles['form-right']}>
                <DatePickerJp
                  inputClassName={mainStyles['w-100']}
                  shouldDisableDate={shouldDisableDate}
                  value={fromDate}
                  onChange={(newValue) => {
                    setForm({ ...form, scheduledDate: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                    setFromDate(newValue);
                  }}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disabled={!selectedCollectAndDeliver}
                  disableTyping
                />
              </div>
            </div>
          </>
        )}
        <div className={`${styles['form-row']}`}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            寺田倉庫への連絡事項
          </div>
          <textarea
            style={{ width: '500px' }}
            className={`${mainStyles['text-area']} ${mainStyles['mr-20px']}`}
            defaultValue=""
            value={form.trdContact}
            placeholder="寺田倉庫へ連絡事項がある場合は入力してください。（100文字以内）"
            onChange={(e) => setForm({ ...form, trdContact: e.target.value })}
          />
        </div>

        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            お客様メモ
          </div>
          <textarea
            style={{ width: '500px' }}
            className={mainStyles['text-area']}
            defaultValue=""
            value={form.userMemo}
            placeholder="その他、依頼に対して備考がある場合は入力してください。"
            onChange={(e) => setForm({ ...form, userMemo: e.target.value })}
          />
        </div>

      </div>

      <div className={styles['modal-footer']}>
        <Button
          size="smaller"
          color="lighterGray"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="smaller"
          onClick={handleSubmit}
          loading={storeDocumentWarehousing.loading}
        >
          依頼
        </Button>
        <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} />
        <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} closeButtonText="OK" />
        {(deliveryMethodLoading || deliveryCalendarLoading) && <LoadingOverlay />}
      </div>
    </ScreenModal>
  );
}

export default Component;
